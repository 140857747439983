<script setup lang="ts">
import { CmsPage, CmsSection } from '@shopware-pwa/types';
import { pascalCase } from 'scule';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import { useNavigationContext } from '@shopware-pwa/composables-next';
import { useListing } from '@/composables/useListing';
import { useInternalLinking } from '@/composables/useInternalLinking';

const props = defineProps<{
  content: CmsPage;
}>();

/**
 * cms
 */
const { routeName } = useNavigationContext();

if (routeName.value === 'frontend.navigation.page') {
  const LISTING_TYPE = 'categoryListing';
  useListing({ listingType: LISTING_TYPE }, props.content);
}

const cmsSections = computed<CmsSection[]>(() => {
  return props.content?.sections;
});

const RenderContent = () => {
  const componentsMap = cmsSections.value.map((section) => {
    return {
      component: resolveComponent(`CmsSection${pascalCase(section.type)}`),
      section: section,
    };
  });
  return componentsMap.map((componentObject) => {
    const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(
      componentObject.section
    );

    if (typeof componentObject.component === 'string')
      return h('div', {}, 'There is no ' + componentObject.component);

    // Replace dynamic content
    const { replaceContent } = useInternalLinking();
    componentObject.section = replaceContent(componentObject.section);

    return h(componentObject.component, {
      content: componentObject.section,
      class: {
        [cssClasses ?? '']: true,
        'max-w-screen-xl mx-auto': layoutStyles?.sizingMode === 'boxed',
      },
      style: {
        backgroundColor: layoutStyles?.backgroundColor,
        backgroundImage: layoutStyles?.backgroundImage,
        backgroundSize: layoutStyles?.backgroundSize,
      },
    });
  });
};
</script>

<template>
  <RenderContent />
</template>

<style>
.mx-auto {
  margin: 0 auto;
}

.container,
.max-w-screen-xl {
  max-width: 1240px;
}
</style>
