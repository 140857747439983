import { useI18n } from 'vue-i18n';

export const useInternalLinking = () => {
  const { locale } = useI18n();

  // TODO: Replace the {{domain_url}} mark to the real domain URL with locale because BE don't know about full referrer URL
  const replaceContent = (section: any) =>
    JSON.parse(
      JSON.stringify(section).split('{{domain_url}}').join(`/${locale.value}`)
    );

  return { replaceContent };
};
